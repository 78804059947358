import React, { useRef, useState, useEffect, useMemo } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { EffectComposer, Bloom, Noise } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import * as THREE from "three";
import {
  FaGithub,
  FaLinkedin,
  FaEnvelope,
  FaDownload,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const CustomCursor = () => {
  const cursorRef = useRef(null);
  const ringRef = useRef(null);

  useEffect(() => {
    const cursor = cursorRef.current;
    const ring = ringRef.current;

    const handleMouseMove = (e) => {
      const x = e.clientX;
      const y = e.clientY;

      cursor.style.transform = `translate(${x}px, ${y}px) translate(-50%, -50%)`;

      ring.animate(
        [{ transform: `translate(${x}px, ${y}px) translate(-50%, -50%)` }],
        {
          duration: 300,
          fill: "forwards",
          easing: "cubic-bezier(0.4, 0, 0.2, 1)",
        },
      );
    };

    const handleMouseDown = () => {
      cursor.animate(
        [
          {
            width: "8px",
            height: "8px",
            backgroundColor: "rgba(255, 105, 180, 1)",
          },
        ],
        { duration: 100, fill: "forwards" },
      );

      ring.animate(
        [
          {
            width: "30px",
            height: "30px",
            borderColor: "rgba(255, 105, 180, 0.8)",
          },
        ],
        { duration: 100, fill: "forwards" },
      );
    };

    const handleMouseUp = () => {
      cursor.animate(
        [
          {
            width: "10px",
            height: "10px",
            backgroundColor: "rgba(255, 105, 180, 0.7)",
          },
        ],
        { duration: 100, fill: "forwards" },
      );

      ring.animate(
        [
          {
            width: "40px",
            height: "40px",
            borderColor: "rgba(255, 105, 180, 0.5)",
          },
        ],
        { duration: 100, fill: "forwards" },
      );
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <>
      <div
        ref={cursorRef}
        style={{
          position: "fixed",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "rgba(255, 105, 180, 0.7)",
          pointerEvents: "none",
          zIndex: 9999,
        }}
      />
      <div
        ref={ringRef}
        style={{
          position: "fixed",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          border: "2px solid rgba(255, 105, 180, 0.5)",
          pointerEvents: "none",
          zIndex: 9999,
          animation: "pulse 2s infinite",
        }}
      />
      <style>{`
        @keyframes pulse {
          0% { transform: scale(1); opacity: 0.7; }
          50% { transform: scale(1.1); opacity: 0.5; }
          100% { transform: scale(1); opacity: 0.7; }
        }
        body { cursor: none; }
      `}</style>
    </>
  );
};

const CameraRig = ({ mousePosition }) => {
  const { camera } = useThree();
  const targetPosition = useRef(new THREE.Vector3(0, 0, 5));
  const targetLookAt = useRef(new THREE.Vector3(-3.5, 0, 0));
  const currentVelocity = useRef(new THREE.Vector3(0, 0, 0));
  const rotationVelocity = useRef(new THREE.Vector3(0, 0, 0));

  const springStrength = 0.02;
  const dampingFactor = 0.85;
  const maxSpeed = 0.1;

  useFrame(() => {
    if (!mousePosition.current) return;

    const targetX = -3.5 + mousePosition.current.x * 2;
    const targetY = mousePosition.current.y * 2;
    const targetZ = 5 + Math.abs(mousePosition.current.x) * 2;

    targetPosition.current.set(targetX, targetY, targetZ);

    const positionDelta = new THREE.Vector3().subVectors(
      targetPosition.current,
      camera.position,
    );

    currentVelocity.current.add(positionDelta.multiplyScalar(springStrength));

    currentVelocity.current.multiplyScalar(dampingFactor);

    if (currentVelocity.current.length() > maxSpeed) {
      currentVelocity.current.normalize().multiplyScalar(maxSpeed);
    }

    camera.position.add(currentVelocity.current);

    const currentLookAt = new THREE.Vector3();
    camera.getWorldDirection(currentLookAt);
    const targetDirection = new THREE.Vector3()
      .subVectors(targetLookAt.current, camera.position)
      .normalize();

    const rotationDelta = new THREE.Vector3()
      .crossVectors(currentLookAt, targetDirection)
      .multiplyScalar(0.02);

    rotationVelocity.current.add(rotationDelta);
    rotationVelocity.current.multiplyScalar(0.95);

    camera.rotateOnAxis(
      rotationVelocity.current,
      rotationVelocity.current.length(),
    );
  });

  return null;
};

const Tetrahedron = () => {
  const tetrahedronRef = useRef();
  const [hovered, setHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const geometry = useMemo(() => {
    const geo = new THREE.BufferGeometry();

    const vertices = new Float32Array([
      1,
      0,
      -1 / Math.sqrt(2),
      -1,
      0,
      -1 / Math.sqrt(2),
      0,
      0,
      Math.sqrt(2),
      0,
      Math.sqrt(6) / 2,
      0,
    ]);

    const indices = new Uint16Array([0, 1, 2, 0, 1, 3, 1, 2, 3, 2, 0, 3]);

    geo.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
    geo.setIndex(new THREE.BufferAttribute(indices, 1));
    geo.computeVertexNormals();

    return geo;
  }, []);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <mesh
      ref={tetrahedronRef}
      geometry={geometry}
      scale={isMobile ? 0.7 : 1}
      position={[-4, 0, 0]}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
    >
      <meshPhongMaterial
        color={hovered ? "#ff69b4" : "#ff1493"}
        wireframe={true}
        emissive={hovered ? "#ff69b4" : "#ff1493"}
        emissiveIntensity={2}
        shininess={100}
      />
    </mesh>
  );
};

const BlinkingStar = ({
  position,
  baseScale = 1,
  blinkSpeed = 1,
  delay = 0,
}) => {
  const starRef = useRef();

  useFrame(({ clock }) => {
    if (starRef.current) {
      const time = clock.getElapsedTime();
      const blink1 = Math.sin(time * blinkSpeed * 2 + delay) * 1.5;
      const blink2 = Math.sin(time * (blinkSpeed * 3.14) + delay) * 1.2;
      const blink3 = Math.sin(time * (blinkSpeed * 0.7) + delay) * 0.8;
      const blinkValue = blink1 * 0.5 + blink2 * 0.3 + blink3 * 0.2;
      const scale = (baseScale * (0.2 + (blinkValue + 1.5) * 0.8)) / 1.5;

      const material = starRef.current.material;
      const brightness = 0.8 + (blinkValue + 1) * 0.2;
      material.color.setRGB(brightness, brightness, brightness);

      starRef.current.scale.set(scale, scale, scale);
    }
  });

  return (
    <mesh ref={starRef} position={position}>
      <sphereGeometry args={[0.05, 8, 8]} />
      <meshBasicMaterial color="#ffffff" />
    </mesh>
  );
};

const generateStarPositions = (count, radius) => {
  const positions = [];
  for (let i = 0; i < count; i++) {
    const theta = 2 * Math.PI * Math.random();
    const phi = Math.acos(2 * Math.random() - 1);
    const r = radius * Math.cbrt(Math.random());

    positions.push([
      r * Math.sin(phi) * Math.cos(theta),
      r * Math.sin(phi) * Math.sin(theta),
      r * Math.cos(phi),
    ]);
  }
  return positions;
};

const BlinkingStarField = ({ count = 200, radius = 50 }) => {
  const starPositions = useMemo(
    () => generateStarPositions(count, radius),
    [count, radius],
  );
  const groupRef = useRef();

  return (
    <group ref={groupRef}>
      {starPositions.map((position, i) => (
        <BlinkingStar
          key={i}
          position={position}
          baseScale={0.2 + Math.random() * 0.3}
          blinkSpeed={0.2 + Math.random() * 3}
          delay={Math.random() * 1000}
        />
      ))}
    </group>
  );
};

const Scene = () => {
  const mousePosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {

      mousePosition.current = {
        x: (event.clientX / window.innerWidth) * 2 - 1,
        y: -(event.clientY / window.innerHeight) * 2 + 1,
      };
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <>
      <CameraRig mousePosition={mousePosition} />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} intensity={1} />
      <spotLight
        position={[5, 5, 5]}
        angle={0.3}
        penumbra={1}
        intensity={1}
        castShadow
      />
      <Tetrahedron />
      <BlinkingStarField count={2000} radius={50} />
      <EffectComposer>
        <Bloom
          intensity={2}
          luminanceThreshold={0.2}
          luminanceSmoothing={0.9}
          height={300}
        />
        <Noise premultiply blendFunction={BlendFunction.SCREEN} opacity={0.1} />
      </EffectComposer>
    </>
  );
};

const App = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const projects = [
    {
      title: "CiviCalc",
      description:
        "A chatbot for civil engineering formulas and concepts with an interactive React UI.",
      techStack: "Flask, React, Langchain",
      link: "https://github.com/chaitanya-p23/CiviCalc",
    },
    {
      title: "ShopSmart",
      description:
        "A Tkinter-based Python app that compares prices, delivery, and ratings across multiple e-commerce platforms.",
      techStack: "Tkinter, Web Scraping, Python",
      link: "https://github.com/chaitanya-p23/ShopSmart",
    },
    {
      title: "FaunaFinder",
      description:
        "An AI-powered web app that identifies animals and birds from images using web scraping and TensorFlow.",
      techStack: "React, Flask, TensorFlow, Web Scraping",
      link: "https://github.com/chaitanya-p23/FaunaFinder",
    },
    {
      title: "WashTally",
      description:
        "A laundry tracker app to manage and monitor laundry tasks efficiently.",
      techStack: "React, LocalStorage",
      link: "https://github.com/chaitanya-p23/WashTally",
    },
    {
      title: "Wearable Band Analytics",
      description:
        "Analyzed accelerometer & gyroscope data for athlete performance feedback.",
      techStack: "Python, Machine Learning",
      link: "https://github.com/chaitanya-p23",
    },
  ];

  const education = [
    {
      level: "10th Standard",
      institute: "General Education Institute, Uran, Maharashtra",
      percentage: "91.00%",
    },
    {
      level: "12th Standard",
      institute: "Uran Education Society, Uran, Maharashtra",
      percentage: "93.83%",
    },
    {
      level: "Computer Science Engineering (3rd year)",
      institute: "Vellore Institute of Technology, Chennai, Tamil Nadu",
      gpa: "9.43",
    },
  ];

  const technicalSkills = [
    "Web Development",
    "AI & Machine Learning",
    "Data Analysis",
    "Cloud Computing",
    "UI/UX Design",
  ];

  const programmingLanguages = [
    "Python",
    "C/C++",
    "Java",
    "JavaScript",
    "PHP",
  ];

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const homeSection = document.getElementById("home");
      const scrollPosition = window.scrollY;

      if (scrollPosition < homeSection.offsetHeight - 100) {
        setActiveSection("home");
        return;
      }

      sections.forEach((section) => {
        const top = section.offsetTop - 100;
        const bottom = top + section.offsetHeight;
        if (scrollPosition >= top && scrollPosition < bottom) {
          setActiveSection(section.id);
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Cursor */}
      <CustomCursor />

      {/* Header */}
      <header className="fixed w-full z-50 backdrop-blur-md bg-gray-900/80 transition-colors duration-300">
        <div className="max-w-7xl mx-auto px-4 py-4 flex flex-col md:flex-row items-center justify-center">
          <motion.h1
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="text-2xl md:text-3xl font-bold text-white mb-4 md:mb-0 md:absolute md:left-4"
          >
            Portfolio
          </motion.h1>

          {/* Navigation */}
          <nav className="hidden md:flex space-x-8 justify-center">
            {["home", "projects", "education", "skills"].map((section) => (
              <motion.a
                key={section}
                href={`#${section}`}
                whileHover={{ scale: 1.1 }}
                className={`text-lg capitalize ${
                  activeSection === section ? "text-pink-500" : "text-white"
                } hover:text-pink-500 transition-colors`}
              >
                {section}
              </motion.a>
            ))}
          </nav>

          {/* Mobile menu button */}
          <button
            className="md:hidden absolute right-4 top-4 text-white"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={
                  isMenuOpen
                    ? "M6 18L18 6M6 6l12 12"
                    : "M4 6h16M4 12h16M4 18h16"
                }
              />
            </svg>
          </button>
        </div>

        <AnimatePresence>
          {isMenuOpen && (
            <motion.nav
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="md:hidden"
            >
              {["home", "projects", "education", "skills"].map((section) => (
                <a
                  key={section}
                  href={`#${section}`}
                  onClick={() => setIsMenuOpen(false)}
                  className={`block px-4 py-2 text-lg capitalize ${
                    activeSection === section ? "text-pink-500" : "text-white"
                  } hover:bg-gray-800`}
                >
                  {section}
                </a>
              ))}
            </motion.nav>
          )}
        </AnimatePresence>
      </header>

      {/* Hero section */}
      <div className="relative h-screen flex" id="home">
        {/* Canvas background */}
        <div className="absolute inset-0">
          <Canvas
            camera={{ position: [0, 0, 5], fov: 75 }}
            style={{ width: "100%", height: "100%" }}
          >
            <OrbitControls
              enableZoom={false}
              enablePan={false}
              minPolarAngle={Math.PI / 2.5}
              maxPolarAngle={Math.PI / 1.5}
            />
            <Scene />
          </Canvas>
        </div>

        {/* Content overlay */}
        <div className="absolute inset-0 flex">
          <div className="hidden md:block md:w-1/2" />{" "}
          <div className="w-full md:w-1/2 flex items-center px-4 md:px-0 md:-ml-20">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="w-full text-center md:text-left"
            >
              <h2 className="text-4xl md:text-6xl font-extrabold text-white mb-4">
                Hi, I'm{" "}
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-500">
                  Chaitanya!
                </span>
              </h2>
              <p className="mt-4 text-lg md:text-2xl text-gray-300">
                Engineering the Impossible, Because Why Not?
              </p>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="mt-8 flex flex-wrap justify-center md:justify-start gap-4"
              >
                {[
                  {
                    icon: <FaGithub size={24} />,
                    href: "https://github.com/chaitanya-p23",
                    color: "hover:bg-[#2ea44f] hover:text-white",
                  },
                  {
                    icon: <FaLinkedin size={24} />,
                    href: "https://linkedin.com/in/chaitanya-p23",
                    color: "hover:bg-blue-700",
                  },
                  {
                    icon: <FaEnvelope size={24} />,
                    href: "mailto:chaitanyapatil.xe@gmail.com",
                    color: "hover:bg-red-700",
                  },
                ].map((item, index) => (
                  <motion.a
                    key={index}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className={`p-4 bg-gray-800 rounded-lg ${item.color} transition-colors`}
                  >
                    {item.icon}
                  </motion.a>
                ))}
                <a href="/assets/Resume.pdf" download>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="p-4 bg-gradient-to-r from-pink-500 to-purple-500 rounded-lg flex items-center gap-2 md:font-bold"
                  >
                    <FaDownload />
                    Download Resume
                  </motion.button>
                </a>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Project section */}
      <section
        id="projects"
        className={`min-h-screen py-20 px-4 bg-gray-900 flex flex-col justify-center`}
      >
        <motion.h3
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className={`text-2xl md:text-4xl font-bold text-center mb-12 text-white`}
        >
          My Projects
        </motion.h3>
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 max-w-6xl mx-auto">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
              className={`p-6 rounded-xl shadow-lg bg-gray-800 hover:bg-gray-700 transition-all`}
            >
              <h4
                className={`text-xl md:text-2xl font-bold mb-3 text-white`}
              >
                {project.title}
              </h4>
              <p
                className={`mb-3 text-gray-300`}
              >
                {project.description}
              </p>
              <p
                className={`mb-4 font-semibold text-gray-400`}
              >
                Tech Stack: {project.techStack}
              </p>
              <a
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-pink-500 hover:text-pink-600 transition-colors"
              >
                View Project →
              </a>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Education section */}
      <section
        id="education"
        className={`min-h-screen py-20 px-4 bg-gray-800 flex flex-col justify-center`}
      >
        <motion.h3
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className={`text-2xl md:text-4xl font-bold text-center mb-12 text-white`}
        >
          Education
        </motion.h3>
        <div className="grid gap-6 max-w-5xl mx-auto">
          {education.map((edu, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className={`bg-gray-900 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all w-full md:w-[110%] lg:w-[115%] justify-self-center`}
            >
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                <div>
                  <h4 className={`text-xl md:text-2xl font-bold text-white`}>
                    {edu.level}
                  </h4>
                  <p className={`mt-2 text-gray-400`}>
                    {edu.institute}
                  </p>
                </div>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className={`px-4 py-2 rounded-full bg-pink-500`}
                >
                  <p className={`text-sm md:text-base font-semibold text-white`}>
                    {edu.percentage ? `${edu.percentage}` : `GPA: ${edu.gpa}`}
                  </p>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Skills section */}
      <section
        id="skills"
        className={`min-h-screen py-20 px-4 bg-gray-900 flex flex-col justify-center`}
      >
        <motion.h3
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className={`text-2xl md:text-4xl font-bold text-center mb-12 text-white`}
        >
          Skills & Expertise
        </motion.h3>
        <div className="grid gap-8 md:grid-cols-2 max-w-6xl mx-auto">
          {/* Technical skills */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className={`bg-gray-800 p-6 rounded-xl shadow-lg`}
          >
            <h4 className="text-xl md:text-2xl font-bold text-center mb-6 text-pink-500">
              Technical Skills
            </h4>
            <div className="grid gap-4">
              {technicalSkills.map((skill, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ scale: 1.02 }}
                  className={`p-4 rounded-lg bg-gray-700 hover:bg-gray-600 transition-all`}
                >
                  <div className="flex items-center justify-between">
                    <span
                      className={`font-medium text-white`}
                    >
                      {skill}
                    </span>
                    <div className="w-2 h-2 rounded-full bg-pink-500"></div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Programming language */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className={`bg-gray-800 p-6 rounded-xl shadow-lg`}
          >
            <h4 className="text-xl md:text-2xl font-bold text-center mb-6 text-pink-500">
              Programming Languages
            </h4>
            <div className="grid gap-4">
              {programmingLanguages.map((lang, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: 20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ scale: 1.02 }}
                  className={`p-4 rounded-lg bg-gray-700 hover:bg-gray-600 transition-all`}
                >
                  <div className="flex items-center justify-between">
                    <span
                      className={`font-medium text-white`}
                    >
                      {lang}
                    </span>
                    <div className="w-2 h-2 rounded-full bg-pink-500"></div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>

      {/* Contact section */}
      <section
        className={`min-h-screen py-20 px-4 bg-gray-800 flex flex-col justify-center`}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-4xl mx-auto text-center"
        >
          <h3
            className={`text-2xl md:text-4xl font-bold mb-6 text-white`}
          >
            Let's Work Together
          </h3>
          <p className={`mb-8 text-gray-300`}>
            Have a project in mind? Let's create something amazing!
          </p>
          <a href="mailto:chaitanyapatil.xe@gmail.com">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-500 text-white rounded-lg font-semibold shadow-lg hover:shadow-xl transition-all"
            >
              Get In Touch
            </motion.button>
          </a>
        </motion.div>
      </section>

      {/* Footer section */}
      <footer
        className={`py-8 px-4 bg-gray-900`}
      >
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p
              className={`text-sm text-gray-400`}
            >
              © 2024 Chaitanya. All rights reserved.
            </p>
            <div className="flex gap-4">
              {[
                {
                  icon: <FaGithub size={20} />,
                  href: "https://github.com/chaitanya-p23",
                },
                {
                  icon: <FaLinkedin size={20} />,
                  href: "https://linkedin.com/in/chaitanya-p23",
                },
                {
                  icon: <FaEnvelope size={20} />,
                  href: "mailto:chaitanyapatil.xe@gmail.com",
                },
              ].map((item, index) => (
                <motion.a
                  key={index}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className={`p-2 rounded-full text-gray-400 hover:text-white transition-colors`}
                >
                  {item.icon}
                </motion.a>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
